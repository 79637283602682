<template>
  <div class="uesrCenter">
    <div class="banner flex-y-s">
      <a href="javasctipt:;" @click="backLevel" class="backBtn">返回</a>
      <a class="homeBtn" @click="backHome" href="javascript:;">首页</a>
      <div>
        <!-- <h3>名称名称</h3> -->
        <p>欢迎您！{{ $store.state.globalInfo.nickname }}</p>
      </div>
    </div>
    <ul>
      <li>
        <div>
          <img src="../../assets/images/userCenter/qy.png" alt="" />
          企业名称
        </div>
        <div>
          <img src="../../assets/detail/jwd.png" alt="" />
          状态
        </div>
        <div>
          <img src="../../assets/images/userCenter/bz.png" alt="" />
          企业群组
        </div>
      </li>
      <!-- <li v-for="item in list" :key="item.uuid" @click="toInformation(item)">
        <div class="blod">{{ item.waCompanyName }}</div>
        <div>{{ item.waGroup }}</div>
        <div>{{ item.waGroup }}</div>
      </li> -->
    </ul>
    <div class="list">
      <div
        v-for="item in list"
        :key="item.uuid"
        @click="toInformation(item)"
        class="item"
      >
        <div class="blod">{{ item.waCompanyName }}</div>
        <div>{{ item.waBind == "1" ? "已绑定" : "未绑定" }}</div>
        <div>{{ item.waGroup }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWechatEnterpriseSupervisionCount } from "../../api/home";
export default {
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.request();
  },
  methods: {
    backLevel(e) {
      e.preventDefault();
      this.$router.go(-1);
      return false;
    },
    backHome(e) {
      e.preventDefault();
      wx.closeWindow();
      return false;
    },
    request() {
      // 获取绑定企业列表
      getWechatEnterpriseSupervisionCount({
        waOpenId: localStorage.getItem("openid"),
        waBind: "1",
      }).then((res) => {
        this.list = res.data;
      });
    },
    toInformation(item) {
      this.$router.push({
        path: "/authorizationManagement",
        query: {
          item: JSON.stringify(item),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  padding: rem(20);
  width: 100%;
  height: rem(342);
  background: url("../../assets/images/userCenter/banner-bg2.png");
  background-size: 100%;
  color: white;
  .title {
    color: #fff;
    font-size: rem(40);
  }
  p {
    font-size: rem(36);
    font-weight: bold;
  }
  img {
    width: rem(36);
    height: rem(36);
  }
}
ul {
  margin: rem(20) auto;
  box-sizing: border-box;
  width: rem(686);
  position: relative;
  top: -40px;
  text-align: center;

  li {
    width: 100%;
    display: flex;
    img {
      width: rem(30);
      height: rem(32);
      position: relative;
      top: 2px;
    }
  }

  div {
    width: 50%;
    text-align: center;
    padding: rem(25) 0;
    font-size: rem(25);
  }
  .blod {
    font-size: rem(24);
    color: #3296fa;
  }
  & > li:first-child {
    background: #fafdff;
    height: rem(96);
    box-shadow: 0 rem(2) rem(16) 0 rgba(0, 0, 0, 0.09);
    border-radius: rem(48);
    position: relative;
    & > div {
      position: relative;
      &:not(:nth-last-of-type(1)):before {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: rem(3);
        height: rem(36);
        background: #3296fa;
      }
    }
  }
}
.list {
  margin: rem(20) auto;
  box-sizing: border-box;
  width: rem(686);
  position: relative;
  top: -40px;
  text-align: center;

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    margin: rem(15) 0 0;
    div {
      flex: 1;
    }
  }
  .blod {
    font-size: rem(24);
    color: #3296fa;
  }
}
</style>
